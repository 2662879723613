import React from 'react';

import { Button } from '@/components';

import styles from './styles.module.scss';

interface Props {
  children: React.ReactNode;
  href?: string;
}

const SimpleHeader = ({ children, href }: Props) => {
  return (
    <div className={styles.simpleHeader} data-testid="simpleHeader">
      {href && (
        <Button href={href} design="ui">
          Back
        </Button>
      )}
      {children}
    </div>
  );
};

export default SimpleHeader;
