import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import { Form, Formik } from 'formik';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { object, string } from 'yup';

import { Button, ErrorMessage, SimpleHeader } from '@/components';
import Field from '@/components/forms/Field';
import { useIsMobile } from '@/hooks';
import { trackEvent } from '@/lib/GA';
import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import { withUrql } from '@/lib/urqlClient';
import UserService from '@/lib/UserService';

import GoogleIcon from '@/images/icons/google.svg';

import styles from './styles.module.scss';

const Login = ({}) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isMobile] = useIsMobile(768);
  const router = useRouter();

  const onSubmit = async (values: UserService.LoginValues) => {
    setSubmitting(true);
    const response = await UserService.loginUser(values);

    if (!response?.errors && response?.user) {
      trackEvent('Login', 'Clicked Log in button');
      if (router?.query?.redirect) {
        window.location.assign(router.query?.redirect as string);
      } else {
        window.location.assign('/');
      }
      return;
    } else if (!!response?.message) {
      setError(response.message);
    } else {
      setError('Unable to login');
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(response);
    }

    setSubmitting(false);
  };

  const initialValues: UserService.LoginValues = { loginName: '', password: '', rememberMe: false };

  return (
    <div className={styles.login} data-testid="login">
      <div className={styles.inner}>
        {isMobile ? <SimpleHeader href="/">Sign In</SimpleHeader> : <h1>Sign in</h1>}
        <div className={styles.socials}>
          <Button design="social" size="large" onClick={() => UserService.socialLogin('google')}>
            <GoogleIcon />
            Login with Google
          </Button>
        </div>
        <h2>Or sign with password</h2>
        <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={Login.validationSchema}>
          {({ errors }) => (
            <Form>
              <Field name="loginName" type="email" required label="Email" errors={errors} formik />
              <Field name="password" type="password" required label="Password" errors={errors} formik />
              <div className={styles.checkbox}>
                Remember Me: <Field name="rememberMe" type="checkbox" formik />
              </div>
              {error && <ErrorMessage error={error} />}
              {submitting ? (
                <div className={styles.spinner}>
                  <Spinner size="xlarge" />
                </div>
              ) : (
                <Button type="submit">Login</Button>
              )}
            </Form>
          )}
        </Formik>
        <div className={styles.forgot}>
          Forgotten your password? <a href="/forgot-password">Click here</a>
        </div>
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(ctx, async () => ({ props: {} }));

Login.validationSchema = object({
  loginName: string().required('Email is required'),
  password: string().required('Please enter your password'),
});

export default withUrql(Login);
